import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { options } from "../components/parser"
import parse from "html-react-parser"
import { GatsbyImage } from "gatsby-plugin-image"
import {
  TextCard,
  SoliCard,
  SoliWhite,
  Text,
  BigText,
  SoliBlack,
} from "../components/styles"
import { BeerFloat } from "../components/headerlinks"

const RightSide = ({
  data: {
    id,
    html,
    frontmatter: { title, bild, bildtext },
  },
}) => (
  <SoliCard id={id}>
    <SoliWhite>
      {title} <BeerFloat />
    </SoliWhite>

    <BigText>{parse(html, options)}</BigText>
  </SoliCard>
)

const Bier = ({ data: { Bier, Bierrechts, Mockup } }) => {
  return (
    <Layout rightside={<RightSide data={Bierrechts} />}>
      {Bier.edges.map(
        (
          {
            node: {
              id,
              html,
              frontmatter: { title, link, bild, bildtext },
            },
          },
          index
        ) => (
          <TextCard id={id} key={id}>
            <SoliBlack>{title}</SoliBlack>
            <Text>
              <GatsbyImage
                image={Mockup.childImageSharp.gatsbyImageData}
                alt={bildtext}
                style={{ float: "right", maxWidth: "50%" }}
              />
              {parse(html, options)}
            </Text>
          </TextCard>
        )
      )}
    </Layout>
  )
}

export default Bier

export const query = graphql`
  query BierQuery {
    Bier: allMarkdownRemark(
      filter: {
        frontmatter: { online: { eq: "ja" }, location: { eq: "Bier" } }
      }
      sort: { fields: [frontmatter___date], order: ASC }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            online
            title
            location
            link
            bild
            bildtext
          }
        }
      }
    }
    Bierrechts: markdownRemark(
      frontmatter: { location: { eq: "Bierrechts" } }
    ) {
      id
      frontmatter {
        bild
        bildtext
        location
        online
        title
      }
      html
    }
    Mockup: file(relativePath: { eq: "Solides.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, height: 600)
        id
      }
    }
  }
`
